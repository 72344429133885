import { MatPaginatorIntl } from '@angular/material/paginator';
import * as i0 from '@angular/core';
import { Injectable, InjectionToken, Component, EventEmitter, Inject, Input, Output, forwardRef, Directive, HostListener, NgModule } from '@angular/core';
import { takeUntil, switchMap, map, filter } from 'rxjs/operators';
import { ReplaySubject, Subject, timer, BehaviorSubject, Subscription, of, noop, first } from 'rxjs';
import * as i1 from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import * as i2 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i1$1 from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import * as i4 from '@angular/material/snack-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import * as i5 from '@angular/common';
import { formatNumber, NgIf } from '@angular/common';
import { MAT_INPUT_VALUE_ACCESSOR, MatInputModule } from '@angular/material/input';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i4$1 from '@angular/material/form-field';
import * as i5$1 from '@angular/material/select';
import { MatSelectModule } from '@angular/material/select';
import * as i6 from '@angular/material/core';
import * as i1$2 from '@angular/router';
import { RouterLink } from '@angular/router';
import { verify } from '@sparbanken-syd/personnummer';
function FooterComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵlistener("click", function FooterComponent_div_1_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.openDialog());
    });
    i0.ɵɵtext(1, "Tredjepartsprogramvara");
    i0.ɵɵelementEnd();
  }
}
const _c0 = a0 => [a0];
class SpbPaginatorSv extends MatPaginatorIntl {
  constructor() {
    super(...arguments);
    this.itemsPerPageLabel = 'Rader per sida';
    this.nextPageLabel = 'Nästa';
    this.previousPageLabel = 'Förra';
    this.firstPageLabel = 'Första';
    this.lastPageLabel = 'Sista';
    this.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return `0 av ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} – ${endIndex} av ${length}`;
    };
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵSpbPaginatorSv_BaseFactory;
      return function SpbPaginatorSv_Factory(t) {
        return (ɵSpbPaginatorSv_BaseFactory || (ɵSpbPaginatorSv_BaseFactory = i0.ɵɵgetInheritedFactory(SpbPaginatorSv)))(t || SpbPaginatorSv);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SpbPaginatorSv,
      factory: SpbPaginatorSv.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpbPaginatorSv, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * this is the Angular 6 way of getting access to the window.
 *
 * Admittedly, I do not understand shit of this!
 *
 * However since we use "window" to change the location and
 * we to be able to mock it so that it does not actually navigate
 * in code when called we need to "inject" window into the application.
 *
 */
/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
const WINDOW = new InjectionToken('window');
/**
 * The provider
 */
const windowProvider = {
  provide: WINDOW,
  useFactory: () => window
};
/**
 * The exported provider(s). We only have one. This is "provided"
 * in app.component.
 */
const WINDOW_PROVIDERS = [windowProvider];
class LicensesDialogComponent {
  static {
    this.ɵfac = function LicensesDialogComponent_Factory(t) {
      return new (t || LicensesDialogComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LicensesDialogComponent,
      selectors: [["spb-licenses-dialog"]],
      decls: 12,
      vars: 1,
      consts: [["matDialogTitle", ""], ["matDialogContent", ""], ["data", i0.ɵɵtrustConstantResourceUrl`/3rdpartylicenses.txt`, "height", "400", "type", "text/plain", "width", "500"], ["href", "/3rdpartylicenses.txt"], ["mat-dialog-actions", ""], ["mat-raised-button", "", 3, "mat-dialog-close"]],
      template: function LicensesDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1, "Tredjepartsprogramvara");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1)(3, "p");
          i0.ɵɵtext(4, "Denna mjukvara anv\xE4nder sig av mjukvara fr\xE5n tredje part.");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div")(6, "object", 2)(7, "a", 3);
          i0.ɵɵtext(8, "Tredjepartsprogramvara");
          i0.ɵɵelementEnd()()()();
          i0.ɵɵelementStart(9, "div", 4)(10, "button", 5);
          i0.ɵɵtext(11, "St\xE4ng ");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(10);
          i0.ɵɵproperty("mat-dialog-close", undefined);
        }
      },
      dependencies: [i1.MatDialogClose, i1.MatDialogTitle, i1.MatDialogActions, i1.MatDialogContent, i2.MatButton],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LicensesDialogComponent, [{
    type: Component,
    args: [{
      selector: 'spb-licenses-dialog',
      template: "<div matDialogTitle>Tredjepartsprogramvara</div>\n<div matDialogContent>\n  <p>Denna mjukvara anv\u00E4nder sig av mjukvara fr\u00E5n tredje part.</p>\n\n  <div>\n    <object data=\"/3rdpartylicenses.txt\" height=\"400\"\n            type=\"text/plain\" width=\"500\">\n      <a href=\"/3rdpartylicenses.txt\">Tredjepartsprogramvara</a>\n    </object>\n  </div>\n</div>\n<div mat-dialog-actions>\n  <button\n    [mat-dialog-close]=\"undefined\"\n    mat-raised-button>St\u00E4ng\n  </button>\n</div>\n"
    }]
  }], null, null);
})();
class VersionService {
  constructor(http) {
    this.http = http;
    this.version$ = new ReplaySubject(1);
    this.cancel$ = new Subject();
  }
  start() {
    timer(0, 60 * 5 * 1000) // We check every 5 minutes
    .pipe(takeUntil(this.cancel$), switchMap(() => this.http.get(`/assets/package.json?ts=${Date.now()}`))).subscribe({
      next: res => {
        this.version$.next(res.version);
      }
    });
  }
  cancel() {
    this.cancel$.next({});
  }
  static {
    this.ɵfac = function VersionService_Factory(t) {
      return new (t || VersionService)(i0.ɵɵinject(i1$1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: VersionService,
      factory: VersionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VersionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.HttpClient
  }], null);
})();
class SettingsService {
  constructor(httpClient) {
    this.httpClient = httpClient;
    /**
     * Consumers should listen to this to get the latest setting.
     */
    this.setting$ = new BehaviorSubject({});
  }
  /**
   * Get a setting from database
   */
  getSetting(settingsServiceUrl) {
    const url = `${settingsServiceUrl}/settings`;
    return this.httpClient.get(url, {
      withCredentials: true
    }).pipe(map(settingItem => {
      this.setting$.next(settingItem);
      return settingItem;
    }));
  }
  /**
   * Save or update a setting to database
   */
  updateSetting(settingsServiceUrl, setting) {
    const url = `${settingsServiceUrl}/settings`;
    return this.httpClient.put(url, setting, {
      withCredentials: true
    }).pipe(map(settingItem => {
      this.setting$.next(settingItem);
      return settingItem;
    }));
  }
  static {
    this.ɵfac = function SettingsService_Factory(t) {
      return new (t || SettingsService)(i0.ɵɵinject(i1$1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SettingsService,
      factory: SettingsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SettingsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.HttpClient
  }], null);
})();
class FooterComponent {
  constructor(dialog, versionService, settingsService, snackBar, injectedWindow) {
    this.dialog = dialog;
    this.versionService = versionService;
    this.settingsService = settingsService;
    this.snackBar = snackBar;
    this.injectedWindow = injectedWindow;
    /**
     * This should be the current release of the user application.
     * It is used to warn if the loaded version differs from the
     * currently deployed version on _the same_ url.
     *
     * See version service for information.
     */
    this.version = 'x.y.x';
    /**
     * Url to settings backend
     */
    this.settingsServiceUrl = '';
    /**
     * The copyright string. If the current year is not included
     * the current year will be appended. Make sure to _not_ add
     * . or comma . before or after.
     */
    this.copyrightYears = '2023';
    /**
     * Show link to 3rdpartylicenses.txt
     */
    this.license = null;
    /**
     * The accessToken, used to check if the user is logged in
     */
    this.token = '';
    /**
     * Tells the site if it should have dark or light theme
     */
    this.darkTheme = new EventEmitter();
    /**
     * Emits true if the user should be logged out
     */
    this.logoutUser = new EventEmitter();
    /**
     * Store a timer subscription.
     */
    this.timerSubscription = new Subscription();
    // Per default, we start the version check and check every 10 minutes.
    this.versionService.start();
  }
  /**
   * Open a dialog with 3rdpartylicensesp
   */
  openDialog() {
    const data = {};
    this.dialog.open(LicensesDialogComponent, {
      data
    });
  }
  ngOnInit() {
    /**
     * Just look for settings if we get an input to the settings backend
     */
    if (this.settingsServiceUrl) {
      this.settingsService.getSetting(this.settingsServiceUrl).pipe(filter(s => s && !!s.itemId)).subscribe({
        next: settings => this.darkTheme.emit(settings.darkTheme)
      });
    }
  }
  ngOnChanges() {
    const thisYear = new Date().getFullYear();
    if (this.copyrightYears.indexOf(thisYear + '') === -1) {
      this.copyrightYears += `, ${thisYear}`;
      this.copyrightYears = this.copyrightYears.replace(/^, /, '');
    }
    this.versionService.version$.pipe(switchMap(version => {
      let res = of({
        dismissedByAction: false
      });
      if (this.version !== version) {
        const ref = this.snackBar.open('Det finns en ny version', 'Uppdatera', {
          horizontalPosition: 'right',
          verticalPosition: 'top'
        });
        res = ref.afterDismissed();
      }
      return res;
    }), filter(res => res.dismissedByAction)).subscribe({
      next: () => {
        this.injectedWindow.location.reload();
      }
    });
    /**
     * Decode the token and check if the session is about to expire, but first stop the timer, if the is any timer.
     */
    this.stopTimer();
    if (this.token) {
      this.decodeToken(this.token);
    }
  }
  /**
   * Decode the accessToken and check if the session is about to expire
   */
  decodeToken(jwt) {
    const sessionTimeLeft = 60 * 5 * 1000; // 5 minutes
    let expireTime;
    try {
      const jwtPayload = jwt.split('.')[1];
      const decodedPayload = window.atob(jwtPayload);
      const payload = JSON.parse(decodedPayload);
      expireTime = payload.exp;
    } catch (_e) {
      // If not a valid token, let us just skip this.
      return;
    }
    this.timerSubscription = timer(0, sessionTimeLeft).subscribe({
      next: () => {
        /**
         * Logout the user if the session has expired
         */
        if (Date.now() > expireTime) {
          this.stopTimer();
          this.snackBar.dismiss();
          this.logoutUser.emit(true);
        } else if (Date.now() > expireTime - sessionTimeLeft) {
          /**
           * Give user a warning if the session is about to expire
           */
          this.showExpiredSessionSnackbar();
        }
      }
    });
  }
  stopTimer() {
    this.timerSubscription.unsubscribe();
  }
  /**
   * Show a snackbar to the user if the session is about to expire
   * @private
   */
  showExpiredSessionSnackbar() {
    this.snackBar.open('Din session går ut snart. Vänligen logga in igen.', 'Logga ut', {
      horizontalPosition: 'right',
      verticalPosition: 'top'
    }).afterDismissed().pipe(filter(res => res.dismissedByAction)).subscribe({
      next: () => {
        /**
         * Logout the user
         */
        this.logoutUser.emit(true);
        this.stopTimer();
      }
    });
  }
  static {
    this.ɵfac = function FooterComponent_Factory(t) {
      return new (t || FooterComponent)(i0.ɵɵdirectiveInject(i1.MatDialog), i0.ɵɵdirectiveInject(VersionService), i0.ɵɵdirectiveInject(SettingsService), i0.ɵɵdirectiveInject(i4.MatSnackBar), i0.ɵɵdirectiveInject(WINDOW));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FooterComponent,
      selectors: [["spb-footer"]],
      inputs: {
        version: "version",
        settingsServiceUrl: "settingsServiceUrl",
        copyrightYears: "copyrightYears",
        license: "license",
        token: "token"
      },
      outputs: {
        darkTheme: "darkTheme",
        logoutUser: "logoutUser"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 8,
      vars: 3,
      consts: [[1, "contact"], ["class", "pointer", 3, "click", 4, "ngIf"], [1, "filler"], [1, "bottom-foot"], [1, "pointer", 3, "click"]],
      template: function FooterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, FooterComponent_div_1_Template, 2, 0, "div", 1);
          i0.ɵɵelement(2, "div", 2);
          i0.ɵɵelementStart(3, "div");
          i0.ɵɵtext(4);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(5, "div", 3)(6, "div");
          i0.ɵɵtext(7);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(1);
          i0.ɵɵproperty("ngIf", ctx.license);
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1("v", ctx.version, "");
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1("All Rights Reserved Sparbanken Syd \xA9 ", ctx.copyrightYears, "");
        }
      },
      dependencies: [i5.NgIf],
      styles: [".contact[_ngcontent-%COMP%]{background-color:#fedd46;color:#fedd46;display:flex;justify-content:space-between;margin-top:50px;min-height:20px;width:100%}.contact[_ngcontent-%COMP%]   .pointer[_ngcontent-%COMP%]{cursor:pointer}.filler[_ngcontent-%COMP%]{flex-grow:1}.bottom-foot[_ngcontent-%COMP%]{background-color:#000;border-top:1px solid #fedd46;color:#9a9a9a;display:flex;flex-direction:column;font-size:14px;height:90px;justify-content:center;margin:0;text-transform:uppercase;width:100%}.bottom-foot[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]{text-align:center;margin:0 10px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FooterComponent, [{
    type: Component,
    args: [{
      selector: 'spb-footer',
      template: "<div class=\"contact\">\n  <div class=\"pointer\" (click)=\"openDialog()\" *ngIf=\"license\">Tredjepartsprogramvara</div>\n  <div class=\"filler\"></div>\n  <div>v{{version}}</div>\n</div>\n\n<div class=\"bottom-foot\">\n  <div>All Rights Reserved Sparbanken Syd \u00A9 {{copyrightYears}}</div>\n</div>\n",
      styles: [".contact{background-color:#fedd46;color:#fedd46;display:flex;justify-content:space-between;margin-top:50px;min-height:20px;width:100%}.contact .pointer{cursor:pointer}.filler{flex-grow:1}.bottom-foot{background-color:#000;border-top:1px solid #fedd46;color:#9a9a9a;display:flex;flex-direction:column;font-size:14px;height:90px;justify-content:center;margin:0;text-transform:uppercase;width:100%}.bottom-foot>div{text-align:center;margin:0 10px}\n"]
    }]
  }], () => [{
    type: i1.MatDialog
  }, {
    type: VersionService
  }, {
    type: SettingsService
  }, {
    type: i4.MatSnackBar
  }, {
    type: Window,
    decorators: [{
      type: Inject,
      args: [WINDOW]
    }]
  }], {
    version: [{
      type: Input
    }],
    settingsServiceUrl: [{
      type: Input
    }],
    copyrightYears: [{
      type: Input
    }],
    license: [{
      type: Input
    }],
    token: [{
      type: Input
    }],
    darkTheme: [{
      type: Output
    }],
    logoutUser: [{
      type: Output
    }]
  });
})();

/**
 * Use this to get formatted numbers as "20 000 000". When doing
 * so make sure your input is "text" but expect numbers back.
 */
class FormatNumberDirective {
  /**
   * The element as injected.
   */
  constructor(elementRef) {
    this.elementRef = elementRef;
    /**
     * Must always be a number. This will hold the value
     * w/o spaces.
     */
    this.pValue = 0;
    /**
     * Placeholders for the callbacks which are later provided
     */
    this.onTouched = noop;
    this.onChange = noop;
  }
  static detectDecimalValues(value) {
    const values = [',', '.'];
    let haveValue = false;
    values.forEach(comma => {
      if (value.indexOf(comma) !== -1) {
        haveValue = true;
      }
    });
    return haveValue;
  }
  /**
   *
   * This is called on every input in the input
   */
  onInput(value) {
    this.formatValue(value); // Sets the display value to 100 000 etc.
    this.unFormatValue(); // Sets pValue to a string w/o spaces.
    this.onChange(this.pValue); // Pass along to other validators.
  }
  onBlur() {
    this.onTouched(this.pValue);
  }
  /**
   * Called from the outside, when the value is set
   * from code, not from edits in the form. Important
   * we make the assumption that this is always
   * numbers.
   */
  writeValue(value) {
    if (value === 0) {
      this.elementRef.nativeElement.value = '';
    } else {
      this.formatValue(value);
    }
  }
  /**
   * Replaces the onChanege and onTouched
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /**
   * Set "our" value to 10000
   */
  unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    // Convert decimal ',' to '.' and remove spaces.
    this.pValue = +value.replace(',', '.').replace(/[^\d.-]/g, '');
  }
  /**
   * Set the element to "100 000" format.
   */
  formatValue(val) {
    let value = val + ''; // If a number coerce to string
    value = value.replace(/[^\d,.-]/g, '');
    if (value === '') {
      this.elementRef.nativeElement.value = value;
    } else if (FormatNumberDirective.detectDecimalValues(value)) {
      value = value.replace(',', '.');
      this.elementRef.nativeElement.value = formatNumber(+value, 'fr', '1.2-2');
    } else {
      this.elementRef.nativeElement.value = formatNumber(+value, 'fr', '1.0-0');
    }
  }
  static {
    this.ɵfac = function FormatNumberDirective_Factory(t) {
      return new (t || FormatNumberDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FormatNumberDirective,
      selectors: [["input", "spbFormatNumber", ""]],
      hostBindings: function FormatNumberDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("input", function FormatNumberDirective_input_HostBindingHandler($event) {
            return ctx.onInput($event.target.value);
          })("blur", function FormatNumberDirective_blur_HostBindingHandler() {
            return ctx.onBlur();
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_INPUT_VALUE_ACCESSOR,
        useExisting: FormatNumberDirective
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormatNumberDirective),
        multi: true
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormatNumberDirective, [{
    type: Directive,
    args: [{
      selector: 'input[spbFormatNumber]',
      providers: [{
        provide: MAT_INPUT_VALUE_ACCESSOR,
        useExisting: FormatNumberDirective
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormatNumberDirective),
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    onInput: [{
      type: HostListener,
      args: ['input', ['$event.target.value']]
    }],
    onBlur: [{
      type: HostListener,
      args: ['blur']
    }]
  });
})();

/**
 * Use this to format Sparbanken Syd account numbers
 * to 123 123 123 1 format.
 *
 * Supports clearing number by default (9570 123 123 123 1)
 *
 */
class FormatAccountDirective {
  /**
   * The element as injected.
   */
  constructor(elementRef) {
    this.elementRef = elementRef;
    /**
     * If set to true the clearing number is
     * considered non-existing and will not
     * be used in formatting.
     */
    this.skipClearing = false;
    /**
     * Always a string
     */
    this.pValue = '';
    /**
     * Placeholders for the callbacks which are later provided
     */
    this.onTouched = noop;
    this.onChange = noop;
  }
  /**
   *
   * This is called on every input in the input
   */
  onInput(value, target) {
    this.formatValue(value, target.selectionStart || 0, this.skipClearing ? 13 : 18); // Sets the display value to 123 123 123 1 etc.
    this.unFormatValue(); // Sets pValue to a string w/o spaces.
    this.onChange(this.pValue); // Pass along to other validators.
  }
  onBlur() {
    this.onTouched(this.pValue);
  }
  /**
   * Called from the outside, when the value is set
   * from code, not from edits in the form. Important
   * we make the assumption that this is always
   * numbers.
   */
  writeValue(value) {
    this.formatValue(value, value.length, this.skipClearing ? 13 : 18);
  }
  /**
   * Replaces the onChange and onTouched
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /**
   * Set "our" value to 10000
   */
  unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    // Replace anything not a digit
    this.pValue = value.replace(/\D/g, '');
  }
  /**
   * Set the element to "100 000" format.
   */
  formatValue(val, start, maxLength) {
    const wsIn = (val.match(/\s/g) || []).length;
    const value = formatAccountNumber(val, this.skipClearing);
    const wsOut = (value.match(/\s/g) || []).length;
    const position = start + Math.max(wsOut - wsIn, 0);
    // 18 is max 14 digits + 4 spaces 9570 123 123 123 0
    this.elementRef.nativeElement.value = value.substring(0, maxLength);
    this.elementRef.nativeElement.selectionStart = position;
    this.elementRef.nativeElement.selectionEnd = position;
  }
  static {
    this.ɵfac = function FormatAccountDirective_Factory(t) {
      return new (t || FormatAccountDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FormatAccountDirective,
      selectors: [["input", "spbFormatAccount", ""]],
      hostBindings: function FormatAccountDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("input", function FormatAccountDirective_input_HostBindingHandler($event) {
            return ctx.onInput($event.target.value, $event.target);
          })("blur", function FormatAccountDirective_blur_HostBindingHandler() {
            return ctx.onBlur();
          });
        }
      },
      inputs: {
        skipClearing: "skipClearing"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_INPUT_VALUE_ACCESSOR,
        useExisting: FormatAccountDirective
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormatAccountDirective),
        multi: true
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormatAccountDirective, [{
    type: Directive,
    args: [{
      selector: 'input[spbFormatAccount]',
      providers: [{
        provide: MAT_INPUT_VALUE_ACCESSOR,
        useExisting: FormatAccountDirective
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormatAccountDirective),
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    skipClearing: [{
      type: Input
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event.target.value', '$event.target']]
    }],
    onBlur: [{
      type: HostListener,
      args: ['blur']
    }]
  });
})();
const formatAccountNumber = (input, skipClearing) => {
  let value = input.replace(/\D/g, '');
  const valueLength = skipClearing ? 10 : 14;
  const accountStart = skipClearing ? 0 : 4;
  while (value.length < valueLength) {
    value += ' ';
  }
  const clearNo = skipClearing ? '' : value.substring(0, 4) + ' ';
  const grp1 = value.substring(accountStart, accountStart + 3) + ' '; // 4, 7, or 0, 3
  const grp2 = value.substring(accountStart + 3, accountStart + 6) + ' '; // 7, 10 or 3, 6
  const grp3 = value.substring(accountStart + 6, accountStart + 9) + ' '; // 10, 13, 9, 12
  value = (clearNo + grp1 + grp2 + grp3 + value.substring(accountStart + 9)).trim();
  return value;
};
class SettingsComponent {
  constructor(settingsService, dialogRef, data) {
    this.settingsService = settingsService;
    this.dialogRef = dialogRef;
    this.data = data;
    this.darkTheme = false;
    this.setting = {
      darkTheme: false,
      itemId: '',
      timeStamp: 0,
      version: 0
    };
    this.selectedTheme = '';
  }
  ngOnInit() {
    this.darkTheme = this.data.darkTheme;
    /**
     * Show dark or light in selector
     */
    this.selectedTheme = this.data.darkTheme ? 'dark' : 'light';
  }
  changeTheme(event) {
    this.darkTheme = event === 'dark';
  }
  save() {
    this.settingsService.setting$.pipe(first(), filter(s => !!s)).subscribe({
      next: setting => this.setting = setting
    });
    this.setting.darkTheme = this.darkTheme;
    this.settingsService.updateSetting(this.data.settingsServiceUrl, this.setting).subscribe({
      next: value => this.dialogRef.close(value.darkTheme)
    });
  }
  static {
    this.ɵfac = function SettingsComponent_Factory(t) {
      return new (t || SettingsComponent)(i0.ɵɵdirectiveInject(SettingsService), i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SettingsComponent,
      selectors: [["spb-settings"]],
      decls: 16,
      vars: 4,
      consts: [["matDialogTitle", ""], ["matDialogContent", ""], [3, "value", "selectionChange"], [3, "value"], ["mat-dialog-actions", ""], ["mat-raised-button", "", "color", "primary", 3, "click"], ["mat-raised-button", "", 3, "mat-dialog-close"]],
      template: function SettingsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1, "Inst\xE4llningar");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1)(3, "mat-form-field")(4, "mat-label");
          i0.ɵɵtext(5, "V\xE4lj temal\xE4ge");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(6, "mat-select", 2);
          i0.ɵɵlistener("selectionChange", function SettingsComponent_Template_mat_select_selectionChange_6_listener($event) {
            return ctx.changeTheme($event.value);
          });
          i0.ɵɵelementStart(7, "mat-option", 3);
          i0.ɵɵtext(8, "Ljust l\xE4ge");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "mat-option", 3);
          i0.ɵɵtext(10, "M\xF6rkt l\xE4ge");
          i0.ɵɵelementEnd()()()();
          i0.ɵɵelementStart(11, "div", 4)(12, "button", 5);
          i0.ɵɵlistener("click", function SettingsComponent_Template_button_click_12_listener() {
            return ctx.save();
          });
          i0.ɵɵtext(13, " Spara");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(14, "button", 6);
          i0.ɵɵtext(15, "Avbryt");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(6);
          i0.ɵɵproperty("value", ctx.selectedTheme);
          i0.ɵɵadvance(1);
          i0.ɵɵproperty("value", "light");
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("value", "dark");
          i0.ɵɵadvance(5);
          i0.ɵɵproperty("mat-dialog-close", undefined);
        }
      },
      dependencies: [i1.MatDialogClose, i1.MatDialogTitle, i1.MatDialogActions, i1.MatDialogContent, i2.MatButton, i4$1.MatFormField, i4$1.MatLabel, i5$1.MatSelect, i6.MatOption],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SettingsComponent, [{
    type: Component,
    args: [{
      selector: 'spb-settings',
      template: "<div matDialogTitle>Inst\u00E4llningar</div>\n\n<div matDialogContent>\n\n  <mat-form-field>\n    <mat-label>V\u00E4lj temal\u00E4ge</mat-label>\n    <mat-select [value]=\"selectedTheme\" (selectionChange)=\"changeTheme($event.value)\">\n      <mat-option [value]=\"'light'\">Ljust l\u00E4ge</mat-option>\n      <mat-option [value]=\"'dark'\">M\u00F6rkt l\u00E4ge</mat-option>\n    </mat-select>\n  </mat-form-field>\n\n</div>\n\n<div mat-dialog-actions>\n  <button mat-raised-button color=\"primary\" (click)=\"save()\"> Spara</button>\n  <button [mat-dialog-close]=\"undefined\" mat-raised-button>Avbryt</button>\n</div>\n"
    }]
  }], () => [{
    type: SettingsService
  }, {
    type: i1.MatDialogRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_DIALOG_DATA]
    }]
  }], null);
})();
class LogoComponent {
  constructor() {
    /**
     * Add internal paths like ['/', 'login'] etc.
     * leave it to route to ['/']
     */
    this.routePath = ['/'];
    /**
     * The title to show inside the logo.
     */
    this.title = 'Sparbanken Syd';
  }
  static {
    this.ɵfac = function LogoComponent_Factory(t) {
      return new (t || LogoComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LogoComponent,
      selectors: [["spb-logo"]],
      inputs: {
        routePath: "routePath",
        title: "title"
      },
      decls: 2,
      vars: 4,
      consts: [[1, "logo", 3, "routerLink"]],
      template: function LogoComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c0, ctx.routePath));
          i0.ɵɵadvance(1);
          i0.ɵɵtextInterpolate1(" ", ctx.title, "\n");
        }
      },
      dependencies: [i1$2.RouterLink],
      styles: [".logo[_ngcontent-%COMP%]{align-self:center;background-color:#fedd46;border-radius:5px;color:#000;font-family:serif;font-size:30px;font-weight:500;letter-spacing:-1px;margin:1px 5px 5px 10px;padding:10px;white-space:nowrap}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LogoComponent, [{
    type: Component,
    args: [{
      selector: 'spb-logo',
      template: "<div [routerLink]=\"[routePath]\" class=\"logo\">\n  {{title}}\n</div>",
      styles: [".logo{align-self:center;background-color:#fedd46;border-radius:5px;color:#000;font-family:serif;font-size:30px;font-weight:500;letter-spacing:-1px;margin:1px 5px 5px 10px;padding:10px;white-space:nowrap}\n"]
    }]
  }], null, {
    routePath: [{
      type: Input
    }],
    title: [{
      type: Input
    }]
  });
})();
class ThemeModule {
  static {
    this.ɵfac = function ThemeModule_Factory(t) {
      return new (t || ThemeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ThemeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [WINDOW_PROVIDERS],
      imports: [HttpClientModule, MatSnackBarModule, MatDialogModule, MatButtonModule, MatInputModule, MatSelectModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeModule, [{
    type: NgModule,
    args: [{
      declarations: [FooterComponent, LicensesDialogComponent, FormatNumberDirective, FormatAccountDirective, SettingsComponent, LogoComponent],
      imports: [HttpClientModule, MatSnackBarModule, NgIf, MatDialogModule, MatButtonModule, MatInputModule, MatSelectModule, RouterLink],
      providers: [WINDOW_PROVIDERS],
      exports: [SettingsComponent, FooterComponent, LogoComponent, FormatNumberDirective, FormatAccountDirective]
    }]
  }], null, null);
})();

/**
 * Validates a Sparbanken Syd account number. 10 digits.
 * Luhn 10 modulus is used
 * Skipped if length != 10
 *
 * @param control - Any control with a string value.
 */
const accountNoValidator = control => {
  let val = control.value;
  while (val.length < 10) {
    val = '0' + val;
  }
  const arr = [...val];
  const last = Number.parseInt(arr.pop(), 10);
  const luhn = calculate(arr);
  if (luhn === last) {
    return null;
  }
  return {
    error: 'Kontrollfel: ' + luhn
  };
};
const calculate = numbers => {
  let sum = 0;
  const multipliers = [2, 1];
  do {
    const num = Number.parseInt(numbers.pop(), 10);
    let product = num * multipliers[0];
    multipliers.reverse();
    if (product > 9) {
      product = product - 9;
    }
    sum += product;
  } while (numbers.length > 0);
  return sum % 10 ? 10 - sum % 10 : 0;
};

/**
 * Validates a personal identification number (personnummer) using our personnummer lib
 * If the provided value is a valid personal identification number, the function sets the form control's value
 * If the value is not a valid personal identification number, an error is returned with the error message.
 *
 * @param control - The form control to be validated.
 * @returns A ValidationErrors object if the value is invalid, otherwise null.
 */
const pnrValidator = control => {
  try {
    const res = verify(control.value);
    // Set the form control's value to the validated value only if it's different
    if (control.value !== res['13']) {
      control.setValue(res['13'], {
        onlySelf: true,
        emitEvent: false
      });
    }
    return null; // Return null when the value is valid.
  } catch (e) {
    return {
      error: e.message
    };
  }
};

/*
 * Public API Surface of theme
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FooterComponent, FormatAccountDirective, FormatNumberDirective, LogoComponent, SettingsComponent, SpbPaginatorSv, ThemeModule, accountNoValidator, formatAccountNumber, pnrValidator };
